<template>
  <div class="content hastip">
    <div class="container"></div>
    <div class="logo">
      <img :src="logo" alt="" class="logo" />
    </div>
    <div class="btn-container">
      <div class="label">
        <div class="title" id="title">{{ name }}</div>
      </div>
    </div>
    <div class="qr btn-container">
      <p class="down">手机扫码下载App</p>
      <p class="down p">iOS & Android</p>
      <div class="qrcode-box">
        <div id="qrcode">
          <vue-qrcode :value="baseUrl" :options="{ width: 200, margin: 0 }"></vue-qrcode>
        </div>
      </div>
    </div>

    <footer>Copyright &copy; 2024 {{ name }}</footer>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  props: ["type", "name", 'logo'],
  data() {
    return {
      baseUrl: location.origin,
    };
  },
};
</script>


<style scoped>
* {
  margin: 0;
  padding: 0;
}

.down {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  color: #999;
}

.down.p {
  line-height: 10px;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, PingFang SC, Helvetica Neue,
    STHeiti, Microsoft Yahei, Tahoma, Simsun, sans-serif;
}

.content {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.content.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content.hastip {
  padding: 60px 0;
}

.isWechat {
  top: 0;
  width: 100%;
  color: #fff;
  font-size: 14px;
  line-height: 2;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.isWechat,
.language {
  position: absolute;
  text-align: center;
}

.language {
  width: 80px;
  height: 30px;
  right: 20px;
  top: 25px;
  border: 1px solid #000;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  z-index: 5;
  color: #000;
  line-height: 30px;
  text-decoration: none;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  /* background: url(../assets/images/hb/ios.png) no-repeat; */
  background-size: cover;
}

.logo {
  width: 140px;
  height: 140px;
  margin: 0 auto 40px;
  -moz-background-size: cover;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
}

.btn-container,
.logo {
  position: relative;
  z-index: 2;
}

.btn-container {
  text-align: center;
}

.label {
  font-size: 16px;
  color: #323232;
  margin-bottom: 10px;
}


.subtitle,
.title {
  text-align: center;

}

.subtitle {
  margin-top: 10px;
  font-size: 20px;
  color: #999;
}

.title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #fff;
}

.feature-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-list .feature {
  color: #323232;
  font-size: 16px;
  padding: 0 15px;
}

.feature-list .gap {
  width: 2px;
  height: 10px;
  background: #999;
}

.btn {
  margin: 40px auto 40px;
  font-size: 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: linear-gradient(90deg,
      rgba(16, 111, 255, 1),
      rgba(63, 207, 255, 1));
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.18);
}

.btn,
.btn a {
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #fff;
}

.btn a {
  display: block;
  text-decoration: none;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: #999;
  font-size: 12px;
  text-align: center;
}

.tips {
  position: relative;
  padding: 0 20px;
  z-index: 2;
}

.tips .title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.tips .step {
  font-size: 14px;
  color: #666;
}

.tips .step .subtip {
  font-size: 12px;
}

.tips img {
  display: block;
  margin: 10px auto;
  width: 280px;
  background-color: #333;
}

.hide {
  display: none;
}

.qrcode-box {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

#qrcode {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  width: 220px;
  height: 220px;
}
</style>
