<template>
  <div class="content hastip">
    <div class="container"></div>
    <div class="logo">
      <img :src="logo" alt="" class="logo" />
    </div>
    <div class="btn-container">
      <div class="label">
        <div class="title" id="title">{{ name }}</div>
      </div>
      <a :href="androidFileUrl" target="_blank">
        <div class="btn android">安卓版App下载</div>
      </a>
    </div>

    <footer>Copyright &copy; 2024 {{ name }}</footer>
  </div>
</template>

<script>
export default {
  props: ["type", "name", 'logo'],
  data() {
    return {
      androidFileUrl: config.androidFileUrl,
    };
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, PingFang SC, Helvetica Neue,
    STHeiti, Microsoft Yahei, Tahoma, Simsun, sans-serif;
}

.content {
  position: relative;
  width: 100vw;
  overflow: hidden;
}

.content.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content.hastip {
  padding: 60px 0;
}




.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  /* background: url(../assets/images/hb/ios.png) no-repeat; */
  background-size: cover;
}

.logo {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  -moz-background-size: cover;
  background-size: cover;
  border-radius: 15px;
}

.btn-container,
.logo {
  position: relative;
  z-index: 2;
}

.btn-container {
  text-align: center;
}

.label {
  font-size: 16px;
  color: #323232;
  margin-bottom: 80px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 20px;
}

.subtitle,
.title {
  text-align: center;
  color: #fff;
}

.subtitle {
  margin-top: 50px;
  font-size: 10px;
}


.btn {
  margin: 20px auto 20px;
  font-size: 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: linear-gradient(90deg,
      rgba(16, 111, 255, 1),
      rgba(63, 207, 255, 1));
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.18);
}

.btn,
.btn a {
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #fff;
}

.btn a {
  display: block;
  text-decoration: none;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 10px;
  color: #999;
  font-size: 10px;
  text-align: center;
}
</style>
